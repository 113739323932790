import {
  init,
  replayIntegration,
  browserTracingIntegration,
  replayCanvasIntegration,
  browserProfilingIntegration,
} from '@sentry/astro';
import posthog from 'posthog-js';

init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  tunnel: import.meta.env.PUBLIC_REVERSE_PROXY_SUBPATH_SENTRY,
  integrations: [
    new posthog.SentryIntegration(
      posthog,
      import.meta.env.PUBLIC_SENTRY_ORG_ID,
      +import.meta.env.PUBLIC_SENTRY_PROJECT_ID,
    ),
    browserProfilingIntegration(),
    browserTracingIntegration({
      tracePropagationTargets: ['localhost', /^\//],
    }),
    replayIntegration(),
    replayCanvasIntegration(),
  ],
  tracesSampler: (samplingContext) => {
    if (samplingContext.transactionContext.name === '/home') {
      return 0.5;
    }
    return 0.1;
  },
  tracesSampleRate: 1,
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});
